import React from 'react';
import '../App.css';

function SSK() {
  // Set the title in the tab
 document.title = "Sanda Team Denmark - SSK"

  return (
    <>
     <div className="contentContainer">
    <div className='banner' style={{ backgroundImage: `url("../img/headkick.png")`, backgroundPositionY: '60px', backgroundPositionX: '40%', minHeight: '600px'}}>
       <div className='title'>
           San Shou Kuan
       </div>
       <div className='titleDesc'>
       Chinese Boxing Club
       </div>
   </div>
   <div className='subBanner'>
            <div className='summary'>
               <div className='icon' style={{backgroundImage: `url("../img/icons/login.png")`}}></div>
               <h2>Login or Create an Account</h2>
               <p>With an account you have access to additional content within the members area, aka 'The Dojo'. Here you can browse training material, including videos, coaching tips and much more. Get in touch and signup today to learn more.</p>
            </div>
            <div className='summary'>
               <div className='icon' style={{backgroundImage: `url("../img/icons/journey4-icon.png")`}}></div>
               <h2>Begin your journey!</h2>
               <p>SSK - Sanda Team Denmark is open to everyone and accommodates all abilities from complete novice to those experienced in martial arts. <a href="https://www.sandateamdk.com/odense" target="_blank">Find a class near you</a> and start your journey in Chinese Boxing with a growing competitive sport of Sanda (San Da)!</p>
            </div>
   </div>
    <div className='pageContent'>
       <h2>What is SSK?</h2>
       <p>SSK is the abbreviation of San Shou Kuan which translates as Chinese Kickboxing School. Classes are taught all over England and now a class has opened in Odense, Denmark. San Shou Kuan is not a new style but an amalgam of the very best of traditional Northern Long Fist (Ch’arng Ch’uen) Shaolin Kungfu with the best aspects of Western Kickboxing. This makes San Shou Kuan predominantly a combative striking art that teaches the student explosive kicking, powerful punching techniques (Punch, chop, elbow, knee and kicks) and takedowns used in Sanda.</p>
       <p>Through its traditional grading syllabus we instill mastery of techniques and train students to ‘deal’ with real life self-defense situations. We aim to educate our students in all aspects of self defense, encompassing punches, kicks, knees, elbows, open hand strikes, takedowns, throwing, ground work, locks and escapes. Weapons are taught to students at later stages (knife, staff, sword and 3 section staff sparring / self-defense).</p>
       <p>San Shou Kuan uses the graded structure so students can monitor their progress, enabling them to learn progressively, in a controlled manner. This graded structure, combined with an innovative competitive framework is seen as a positive enabler to motivate students to excel and ensure age is not a barrier for experienced people with coaching skills.</p>
    </div>
    <div className='pageContentWhite'>
       <h2>The Origins of San Shou Kuan</h2>
       <p>San Shou Kuan – is a Chinese Kickboxing Association. It formed from a group of forward looking martial art instructors led by two Chinese Kickboxing Senior Masters, Peter Kennedy and David Nicholls MSc. (Sports and Exercise Science) who have over 50 years teaching experience in Martial Arts and working with other senior instructors from a variety of backgrounds including active military instructors in Special forces and instructors from other “styles” or schools. (Supplemented with training in various UK boxing clubs and Thai boxing training camps in Thailand).</p>
       <blockquote>
        <p>– We are an “open style” willing to listen as you never stop learning in Martial Arts.</p>
       </blockquote>
    </div>
    <div className='pageContent'>
       <h2>Class Structure</h2>
       <p>San Shou Kuan takes its inspiration from Northern Shaolin Kung Fu. The backbone to San Shou Kuan are our traditional classes. These combine 2 x 1 hour sessions with the prime objective of the class being to develop the students self-confidence, fitness and practical skill set as they master the basic movements, based upon a solid Martial Art coaching foundation by Black Belt instruction.</p>
       <h3>Traditional Classes</h3> 
       <p>Each class begins and ends with a bow. This is followed by a comprehensive warm up with a section of progressive stretching and then drills in the fundamental techniques. These techniques include kicking, punching, stances, blocking and self defense applications. The techniques are performed by the whole class with the movements becoming more demanding. In addition to these core basics each class incorporates bespoke modules of techniques, e.g. jab cross combinations on pads, hooks, uppercuts, kicks to legs, countering kicks to legs etc. These modules are designed to develop you as a martial artist and push you in new areas of development.</p>
       <p>In the second half of the class, students will go through the forms (patterns) and progressive grading syllabus that lead to promotion through the coloured belts towards Black Belt. An aspect of free sparring is incorporated into each class to allow students the opportunity to practice the techniques they have learnt and apply the applications in a controlled but realistic environment. For a swimmer to practice their sport, they have to get wet, much is the same with martial arts.</p>
       <h3>Sanda Classes</h3> 
       <p>Alternatively to traditional classes there are Sanda classes. <b>Sanda Team dk</b> predominantly teaches this type of class which are focused on competitive Sanda combat, where functional drills, combinations and techniques will be instructed and practiced. This will be accompanied by partner pad work, alternate attack/ defense drills and free sparring focused on the techniques instructed in the class.</p>
       <p>Another class structure focuses mainly on competitions and free sparring. Aspects of this class focus specifically on endurance, power training, agility and drilling takedowns, counters and overall standup attack and defense. These classes are designed for those destined for competitive fights, however those passionate and determined to enhance their abilities are more than welcome.</p>
      <p><a href="https://www.sandateamdk.com/odense" target="_blank">Find out more about Sanda here.</a></p>    
    </div>
   </div>
   </>
 );
}

export default SSK;