import React from 'react';
import '../App.css';
import { linkData } from '../data/LinkData';
import NavLinks from './NavLinks';

function MobileNav({isOpen, closeMobileNav}) {
	

  return (
    <div className="mobileMenu"
	style={{
        opacity: !isOpen ? 0 : 1 ,
		visibility: !isOpen ? "hidden" : "visible" ,
      }}
	  >
      <ul className="sideNavContainer">
	  {linkData.map(function(link) {
	 	return (
			<NavLinks
			    key={link.id}
				title={link.title}
				closeMobileNav={closeMobileNav}
				url={link.url}
				icon={link.icon}
				subNav={link.subNav}
			/>
		); 
	})}
	</ul>
    </div>
  );
}

export default MobileNav;