import React from 'react';
import '../App.css';


function OdenseClass() {
   // Set the title in the tab
 document.title = "Sanda Team Denmark - Odense Class"

  return (
    <>
    <div className="contentContainer">
    <div className='banner' style={{ backgroundImage:  `url("../img/sandaFight.png")`, backgroundPositionY: '0px', backgroundPositionX: '0%', minHeight: '600px'}}>
       <div className='title'>
           SANDA
       </div>
       <div className='titleDesc'>
       Class to Open in Odense
       </div>
   </div>
   <div className='subBanner'>
            <div className='summary'>
               <div className='icon' style={{backgroundImage: `url("../img/icons/quest-icon.png"))`}}></div>
               <h2>What is Sanda?</h2>
               <p>Sanda or SanShou is the combative sport associated with Chinese Martial Arts. As the Arts vary in technique and style, Sanda is the platform for the different styles to meet competitively and perform within a set rule base, putting a martial artist's skills to the test.</p>
               <p>Sanda competitions are fast paced and combatants use a variety of explosive techniques to score points for cleanly landed strikes, much like in boxing. Fights are performed on a raised platform, known as a Lei Tai, which has no boundary ropes or barrier so the fighters can be expelled from the arena. This can cost the fighter points and is a great strategy to lower their stamina if not knock their wind out at the least.</p>
            </div>
            <div className='summary'>
               <div className='icon' style={{backgroundImage: `url("../img/icons/journey4-icon.png")`}}></div>
               <h2>Sparring Class</h2>
               <p>The goal is cultivate a class in Odense where different styles can come together to train and sparr under the shared rule set of Sanda. These rules are regulated by the IWUF and can be viewed <a href="https://iwuf.org/wp-content/uploads/2018/12/IWUF-Wushu-Sanda-Competition-Rules-Judging-Method-2017.pdf" target="_blank">here</a>.</p>
               <p>Classes will consist of a comprehensive warm up, followed by drilling of techniques and free sparring.</p>
               <p>As the class and experience level grows, students can take their training to the next level and harness their skills competitively on the lei tai in organised tournments.</p>
            </div>
   </div>
       <div className='pageContent'>
       <h2>What is Sanda and how does SSK fit in?</h2>
       <p>The Primary objective of San Shou Kuan’s approach to martial arts is to train students to master effective self defence whilst improving fitness. From this, the student gains confidence in their personal abilities as a martial artist. San Shou Kuan is a practical martial art based around its members to help them strive for self improvement. The style uses both coaching of individuals together with class based tuition.</p>
       <blockquote><p>“Martial Arts by Martial Artists for aspiring Martial Artists ! – No Politics, No Egos” .</p></blockquote>
       <p>San Shou Kuan translates as Chinese Kickboxing School and classes are taught all over England. San Shou Kuan is not a new style but an amalgam of the very best of traditional Northern Long Fist (Ch’arng Ch’uen) Shaolin Kungfu with the best aspects of Western Kickboxing. This makes San Shou Kuan predominantly a combative striking art that teaches the student explosive kicking, powerful punching techniques (Punch, chop, elbow, knee and kicks) and takedowns used in Sanda.</p>
    </div>
   </div>
   </>
  );
}

export default OdenseClass;