import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { baseURL } from '../shared';
import axios from 'axios';
import '../App.css';
import { useAuth } from '../utils/AuthContext';


function Register() {
   // Set the title in the tab
 document.title = "Sanda Team Denmark - Register"

 const regForm = useRef(null);
 const [inputs, setInputs] = useState({firstname: '', surname: '', email: '', password: '', rpassword: ''});
 const [error, setError] = useState('');
 const [showForm, setShowForm] = useState(true);
 const [btnDisabled, setBtnDisabled] = useState(true);
 const {user} = useAuth();



 const handleChange = (event) => {
    const { name, value} = event.target;

   setInputs(values => ({...values, [name]: value}));
   
    }

 useEffect(() => {
    //Redirect to home if user is logged in - no need to see register page
    
    if(user) {
    routeChange();
    }
}, [user])   

 useEffect(() => {
    console.log(inputs);
    if (!inputs.firstname && !inputs.surname && !inputs.email && !inputs.password)
        console.log('empty field')
    else if ( 
          inputs.firstname.length > 3 && inputs.surname.length > 3 && inputs.password.length > 8 && inputs.password === inputs.rpassword
        ) {
      setBtnDisabled(false)
    } else {
      setBtnDisabled(true)
    }
}, [inputs]);
  

 const handleSubmit = (event) => {
   event.preventDefault();
   // Check the email is set and has a valid structure
   if (!inputs.email) {
     setError('No email address added!') 
   } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputs.email)) {
    setError('Invalid email address')
   } else {
    
    var firstname = inputs.firstname;
    var surname = inputs.surname;
    var email = inputs.email;
    var password = inputs.password;
      
      setError('');
      axios.post(baseURL + '/register', {
        firstname,
        surname,
        email,
        password
      })
      .then(() => {
        alert('Message Sent Successfully');
        event.target.reset();
        setInputs('');
        setShowForm(false);
      })
      .catch((err) => alert('Snap, something is not quite right!' + err))
    }

    console.log('It would have been sent now!!!')

     event.target.reset();
     setInputs('');
     setShowForm(false);
   }
 // Back button navigate to home page
 let navigate = useNavigate(); 
 const routeChange = () =>{ 
   let path = `../`; 
   navigate(path);
 }

  return (
    <div className="contentContainer">
    <div className='banner' style={{ backgroundImage:  `url("../img/sanda1.jpg")`, backgroundPositionY: '65px', backgroundPositionX: '37%', minHeight: '600px'}}>
       <div className='title'>
           Register
       </div>
   </div>
   <div className='subBanner'  style={{ display: 'inline' }}>
   <div className="contact-form">
          {showForm ? (
          <form name='login-form'className="form-container" style={{ height: 'auto', width: '400px' }} ref={regForm} onSubmit={handleSubmit}>
          <div className="form-group">
              <label htmlFor='firstname'>Firstname<span></span></label>
              <input autoFocus={true} className="form-control" type="text" id='firstname' name='firstname' placeholder="Firstname" value={inputs.firstname} onChange={handleChange}/>
           </div>
           <div className="form-group">
              <label htmlFor='surname'>Surname<span></span></label>
              <input className="form-control" type="text" id='surname' name='surname' placeholder="Surname" value={inputs.surname} onChange={handleChange}/>
           </div>
          <div className="form-group">
              <label htmlFor='email address'>Email Address<span></span></label>
              <input className="form-control" type="email" id='email' name='email' placeholder="Email address" value={inputs.email} onChange={handleChange}/>
           </div>
           <div className="form-group">
              <label htmlFor='password'>Password<span></span></label>
              <input className="form-control" type="password" id='password' name='password' placeholder="Password" value={inputs.password} onChange={handleChange}/>
            </div>
            <div className="form-group">
              <label htmlFor='rpassword'>Repeat Password<span></span></label>
              <input className="form-control" type="password" id='rpassword' name='rpassword' placeholder="Repeat password" value={inputs.rpassword} onChange={handleChange}/>
            </div>            
            <span className='quick-link'><Link to='/login'>Already have an account!</Link></span>
            <button className="btn btn-primary btn-block" id="contact"  data-bs-hover-animate="shake" disabled={btnDisabled} type="submit">Login</button>
            <span id="alert">{error}</span>
          </form> ) : (
          <div className="form-container"><div className="notice-text"><p>Thank You! Your message was successfully sent. Someone will be in touch, usually within 48 hours.</p></div>
          <button className="btn btn-primary btn-block" id="contact"  data-bs-hover-animate="shake" disabled="" type="button" onClick={routeChange}>Back To Home</button></div> )
          }
    </div>
   </div>
</div>
  );
}

export default Register;